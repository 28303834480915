import { useMemo } from 'react'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { translate } from '../translation'
import { SmallBadge } from './styles'

const Badge = ({ diet }) => {
  const { locale } = useRouter()

  const translationLabels = useMemo(
    () => ({
      v: translate('product.badge.v', locale)?.value,
      vg: translate('product.badge.vg', locale)?.value
    }),
    [locale]
  )

  const translationTitles = useMemo(
    () => ({
      v: translate('product.badge.abbr.v', locale)?.value,
      vg: translate('product.badge.abbr.vg', locale)?.value
    }),
    [locale]
  )

  const dataTestId = `dietary-badge-${diet}`

  return (
    <SmallBadge
      type={locale === 'en-HK' || locale === 'zh-HK' ? 'pile' : 'circle'}
      data-testid={dataTestId}
      border='green'
    >
      <span className='sr-only'>{translationTitles[diet]}</span>
      <span aria-hidden>{translationLabels[diet]}</span>
    </SmallBadge>
  )
}

Badge.propTypes = {
  diet: PropTypes.oneOf(['v', 'vg']).isRequired
}

export default Badge
