import styled from '@emotion/styled'
import tw from 'twin.macro'
import {
  Badge,
  Star as StarIcon,
  Tick as TickIcon,
  Info as InfoIcon
} from '@pretamanger/component-library'

export const SmallBadge = styled(Badge)`
  ${tw`text-xs leading-xs tracking-xs text-green-700 mr-2`}
  ${props =>
    props.type && props.type === 'pile' && tw`!w-fit !rounded-[1rem] !p-2`}

  span {
    ${tw`text-xs text-green-700 no-underline`}
  }
`

export const LargeBadge = styled(Badge)`
  ${tw`flex flex-col text-xs leading-xs tracking-xs ml-2`}
`

const topRight = 'absolute top-0 right-0'

const positions = {
  tight: tw`${topRight} mr-1 mt-1 py-0 md:py-1 md:text-xs`,
  normal: tw`${topRight} mr-2 mt-2`
}

export const NewBadge = styled(Badge)`
  ${tw`
    border-0 rounded
    h-auto w-auto
    p-1
    text-xs leading-tight tracking-xs
  `}
  ${props => positions[props.variant || 'normal']}
  ${props => props.variant && props.variant === 'tight' && { fontSize: '11px' }}
  > *:first-of-type {
    ${tw`mr-1`}
  }
`

export const Star = styled(StarIcon)`
  ${tw`h-2 w-2 md:h-3 md:w-3`}
`

export const Tick = styled(TickIcon)`
  width: 16px;
  height: 12px;

  path,
  polygon {
    ${tw`text-white stroke-current fill-current`}
  }
`

export const Info = styled(InfoIcon)`
  height: 18px;

  path,
  polygon {
    ${tw`text-white stroke-current fill-current`}
  }
`
