import Translation from '../translation'
import { NewBadge, Star } from './styles'

const Badge = props => (
  <NewBadge size='small' aria-hidden='true' {...props}>
    <Star />
    <Translation id='product.badge.new' />
  </NewBadge>
)

export default Badge
